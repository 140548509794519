import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PromoHero } from 'blocks/Heros/PromoHero'
import { RunningLine } from 'components/RunningLine'
import { Advantages3D } from 'blocks/Advantages3D'
import { Visualization3d } from 'blocks/Visualization3d'
import { ProjectStructure } from 'blocks/ProjectStructure'
import { VisualizationAdvantages } from 'blocks/VisualizationAdvantages'
import { AdvantagesServices } from 'blocks/AdvantagesService'
import { Reviews } from 'blocks/Reviews'
import { Project3D } from 'blocks/Project3D'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { SelectionOfPaints } from 'blocks/SelectionOfPaints'
import { HelmetFunc } from 'components/PageMetaData'
import { Layout } from 'layout/Layout'
import { ShowMore } from 'blocks/ShowMoreBlock'
import { Visualization360 } from 'blocks/Vizualization360'
import { graphql } from 'gatsby'
import { ExamplesOfProjects } from 'blocks/ExamplesOfProjects'
import { Faq } from 'blocks/FAQ/FAQ'
import { imagesDataProp } from 'types/interfaces'
import {
    ProjectQuestionNew,
    ProjectQuestionNewDesktop,
} from 'blocks/ProjectQuestionNew'
import styled from 'styled-components'
import { displayWidth } from 'styles/width'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { VideoBlock } from 'blocks/Video'
import { backgroundColors } from 'styles/colors'

const pageMetadata = {
    ru: {
        title: 'Дизайн интерьера от 1$ за кв.метр',
        description:
            'Самые низкие цены по разработке дизайн-проектов интерьера квартир, домов, коммерческих помещений. Проект содержит все неоходимые чертежи для подбора мебели, составления сметы и выполнения ремонта.',
    },
    uk: {
        title: "Дизайн інтер'єру від 1$ за кв.метр",
        description:
            "Найнижчі ціни по розробці дизайн-проектів інтер'єру квартир, будинків, комерційних приміщень. Проект містить всі необхідні креслення для підбору меблів, складання кошторису та виконання ремонту.",
    },
    en: {
        title: 'Interior design from $1 per square meter',
        description:
            'The lowest prices for the development of interior design projects for apartments, houses, and commercial premises. The project contains all the necessary drawings for selecting furniture, drawing up estimates and performing repairs.',
    },
}

const Wrap = styled.div`
    display: block;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const WrapDesktop = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`

const Promo = ({
    data,
}: {
    data: {
        allImageSharp: imagesDataProp
        allPromoFaqYaml: unknown
        allPromoProjectStructureYaml: unknown
        allPromoVideoYaml: unknown
    }
}) => {
    const [advantages3DIsVisible, setAdvantages3DIsVisible] = useState(false)
    const [
        advantagesServicesIsVisible,
        setAdvantagesServicesIsVisible,
    ] = useState(false)
    const [
        visualizationAdvantagesIsVisible,
        setVisualizationAdvantagesIsVisible,
    ] = useState(false)
    const { t, i18n } = useTranslation()
    const selectedData = getDataByLanguage(
        data.allPromoProjectStructureYaml,
        i18n.language
    )

    const videoData = getDataByLanguage(data.allPromoVideoYaml, i18n.language)

    return (
        <Layout noFooter={visualizationAdvantagesIsVisible}>
            <HelmetFunc data={pageMetadata} />
            <PromoHero imagesData={data.allImageSharp} />
            <RunningLine>{t('designProject99')}</RunningLine>
            {i18n.language === 'ru' && (
                <VideoBlock
                    title={videoData.title}
                    description={videoData.description}
                    color={backgroundColors.promotion}
                    url={'https://www.youtube.com/watch?v=Qu8ifieboqY'}
                />
            )}
            {i18n.language === 'uk' && (
                <VideoBlock
                    title={videoData.title}
                    description={videoData.description}
                    color={backgroundColors.promotion}
                    url={'https://youtu.be/gmtOSQe9j20'}
                />
            )}
            <div id="projectStructure" />
            <ProjectStructure imagesData={data.allImageSharp} />
            <Reviews />
            <Wrap>
                <ProjectQuestionNew selectedData={selectedData} />
            </Wrap>
            <WrapDesktop>
                <ProjectQuestionNewDesktop selectedData={selectedData} />
            </WrapDesktop>
            <Faq
                noH1
                imagesData={data.allImageSharp}
                customFaqData={data.allPromoFaqYaml}
            />
            <ExamplesOfProjects
                withH2Header
                titleTetx={t('exampleHeader')}
                priceText="1$ м²"
            />

            <RunningLine inverse>{t('designProject99')}</RunningLine>

            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormPromoPageBottom',
                    eventCategory: 'Middleform',
                }}
            />
            <div id="SelectionOfPaints" />
            {!advantagesServicesIsVisible && (
                <ShowMore
                    advantages3DIsVisible={advantages3DIsVisible}
                    setAdvantages3DIsVisible={setAdvantages3DIsVisible}
                    advantagesServicesIsVisible={advantagesServicesIsVisible}
                    setAdvantagesServicesIsVisible={
                        setAdvantagesServicesIsVisible
                    }
                    visualizationAdvantagesIsVisible={
                        visualizationAdvantagesIsVisible
                    }
                    setVisualizationAdvantagesIsVisible={
                        setVisualizationAdvantagesIsVisible
                    }
                />
            )}
            {advantagesServicesIsVisible && (
                <>
                    <SelectionOfPaints
                        imagesData={data.allImageSharp}
                        setAdvantagesServicesIsVisible={
                            setAdvantagesServicesIsVisible
                        }
                    />
                    <div id="selectionOfPaintsAdvantages" />
                    <AdvantagesServices imagesData={data.allImageSharp} />
                    <RunningLine>{t('designProject99')}</RunningLine>
                </>
            )}
            <div id="visualization3d" />
            {advantagesServicesIsVisible &&
                !visualizationAdvantagesIsVisible && (
                    <ShowMore
                        advantages3DIsVisible={advantages3DIsVisible}
                        setAdvantages3DIsVisible={setAdvantages3DIsVisible}
                        advantagesServicesIsVisible={
                            advantagesServicesIsVisible
                        }
                        setAdvantagesServicesIsVisible={
                            setAdvantagesServicesIsVisible
                        }
                        visualizationAdvantagesIsVisible={
                            visualizationAdvantagesIsVisible
                        }
                        setVisualizationAdvantagesIsVisible={
                            setVisualizationAdvantagesIsVisible
                        }
                    />
                )}
            {visualizationAdvantagesIsVisible && (
                <>
                    <Visualization3d imagesData={data.allImageSharp} />
                    <div id="visualization3dAdvantages" />
                    <RunningLine inverse>{t('designProject99')}</RunningLine>
                    <Visualization360 />
                    <VisualizationAdvantages imagesData={data.allImageSharp} />
                    <RunningLine>{t('designProject99')}</RunningLine>
                </>
            )}
            <div id="Project3D" />
            {visualizationAdvantagesIsVisible && !advantages3DIsVisible && (
                <ShowMore
                    advantages3DIsVisible={advantages3DIsVisible}
                    setAdvantages3DIsVisible={setAdvantages3DIsVisible}
                    advantagesServicesIsVisible={advantagesServicesIsVisible}
                    setAdvantagesServicesIsVisible={
                        setAdvantagesServicesIsVisible
                    }
                    visualizationAdvantagesIsVisible={
                        visualizationAdvantagesIsVisible
                    }
                    setVisualizationAdvantagesIsVisible={
                        setVisualizationAdvantagesIsVisible
                    }
                />
            )}
            {advantages3DIsVisible && (
                <>
                    <Project3D imagesData={data.allImageSharp} />
                    <div id="project3dAdvantages" />
                    <Advantages3D imagesData={data.allImageSharp} />
                    <RunningLine>{t('designProject99')}</RunningLine>
                </>
            )}
            {advantages3DIsVisible && (
                <DefaultFormBlock
                    withPhoneMobile
                    tracking={{
                        conversionType: 'FormPromoPageBottom',
                        eventCategory: 'Bottomform',
                    }}
                />
            )}
        </Layout>
    )
}

export default Promo

export const query = graphql`
    query {
        allPromoVideoYaml {
            edges {
                node {
                    title
                    description
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allPromoProjectStructureYaml {
            edges {
                node {
                    title
                    price
                    promo
                    itemsNew {
                        question
                        answer
                        svg
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allPromoFaqYaml {
            edges {
                node {
                    title
                    image
                    subTitle
                    buttonTextOpen
                    buttonTextClose
                    questions {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [400]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`

import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

import { Container } from 'components/Container'
import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { Title } from 'components/TitleComponent'
import { indent } from 'styles/indent'
import { sendEvent } from 'tracking'

const VideodWrapper = styled.div<{ color: string }>`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${(props) => props.color};
    position: relative;

    ${mobileAfterBorder}
`

const SubTitle = styled.h3`
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${colors.dark};
    margin: 20px;
    @media (min-width: ${displayWidth.tablet}) {
        text-align: left;
        margin: 0 ${indent.heroColumnTablet};
    }
    @media (min-width: ${displayWidth.desktop}) {
        margin: 0px ${indent.heroColumnDesktop};
    }
`

const HeroColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.dark};
    border-top: 1px solid ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
        border-top: none;
        padding: 0 0 32px;
        border-right: 1px solid ${colors.dark};
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 20px 0;
    }
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.white};
    padding: 0 16px;
    @media (min-width: ${displayWidth.tablet}) {
        padding: 0;
    }
`

const Video = styled.div`
    width: 100%;
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    div {
        position: absolute;
        top: 0;
        left: 0px;

        width: 100%;
        height: 100%;
    }
`
const TitleS = styled(Title)`
    @media (min-width: ${displayWidth.desktop}) {
        font-size: 26px;
        line-height: 30px;
        margin: 24px 48px;
    }
`
export const VideoBlock = ({
    url,
    title,
    description = '',
    color = backgroundColors.contact,
}: {
    url: string
    title: string
    description?: string
    color?: string
}) => {
    return (
        <VideodWrapper color={color}>
            <Container columns={'1fr'} tabletColumns={'1fr 2fr'}>
                {!!(title || description) && (
                    <HeroColumn>
                        <TitleS>{title}</TitleS>
                        <SubTitle>{description}</SubTitle>
                    </HeroColumn>
                )}
                <Wrapper>
                    <Video>
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            controls={true}
                            url={url}
                            volume={0.5}
                            onStart={() =>
                                sendEvent('Click', {
                                    eventCategory: 'video-promo',
                                })
                            }
                            config={{
                                youtube: {
                                    playerVars: {
                                        showinfo: 0,
                                        enablejsapi: 0,
                                        rel: 0,
                                        ecver: 2,
                                    },
                                },
                            }}
                        />
                    </Video>
                </Wrapper>
            </Container>
        </VideodWrapper>
    )
}

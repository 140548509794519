import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { sendEvent } from 'tracking'

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    background-color: ${backgroundColors.promotion};
    border-bottom: 1px solid ${colors.dark};
    ${mobileAfterBorder}
`
const ContainerStyled = styled.div`
    max-width: 1190px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    @media (min-width: ${displayWidth.tablet}) {
        border: 1px solid ${colors.dark};
        border-top: none;
        border-bottom: none;
    }
`
const ButtonDesktop = styled(Button)`
    display: none;
    width: 600px;
    margin: 30px auto;
    font-weight: 600;
    background-color: ${backgroundColors.formPromo};
    color: ${colors.dark};
    * {
        pointer-events: none;
    }
    @media (min-width: ${displayWidth.desktop}) {
        display: flex;
        position: relative;
        align-items: flex-end;
        justify-content: flex-end;
    }
    b {
        text-transform: none;
        font-size: 34px;
        /* color: red; */
        font-weight: bold;
        margin: 23px;
        margin: 15px 60px 25px 10px;
    }
    span {
        margin: 20px 5px;
    }
`
const ButtonMobile = styled(Button)`
    font-weight: 600;
    background-color: ${backgroundColors.formPromo};
    color: ${colors.dark};
    margin: 30px auto;
    padding: 15px;
    height: 82px;
    font-weight: bold;
    font-size: 15px;
    * {
        pointer-events: none;
    }
    @media (min-width: 350px) {
        width: 290px;
        padding: 15px 20px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        display: none;
    }
`

export const ShowMore = ({
    advantages3DIsVisible,
    setAdvantages3DIsVisible,
    advantagesServicesIsVisible,
    setAdvantagesServicesIsVisible,
    visualizationAdvantagesIsVisible,
    setVisualizationAdvantagesIsVisible,
}: {
    advantages3DIsVisible: boolean
    setAdvantages3DIsVisible: (arg: boolean) => void
    advantagesServicesIsVisible: boolean
    setAdvantagesServicesIsVisible: (arg: boolean) => void
    visualizationAdvantagesIsVisible: boolean
    setVisualizationAdvantagesIsVisible: (arg: boolean) => void
}) => {
    const { t } = useTranslation()
    const showMoreHandle = () => {
        if (
            !advantages3DIsVisible &&
            !advantagesServicesIsVisible &&
            !visualizationAdvantagesIsVisible
        ) {
            setAdvantagesServicesIsVisible(true)
            sendEvent('Click', {
                eventCategory: 'MoreOffer1',
                placement: 'Promo',
                target: 'Color',
            })
            setTimeout(
                () =>
                    window!
                        .document!.getElementById('SelectionOfPaints')!
                        .scrollIntoView({
                            block: 'start',
                            behavior: 'smooth',
                        }),
                400
            )
        } else if (
            advantagesServicesIsVisible &&
            !advantages3DIsVisible &&
            !visualizationAdvantagesIsVisible
        ) {
            setVisualizationAdvantagesIsVisible(true)

            sendEvent('Click', {
                eventCategory: 'MoreOffer2',
                placement: 'Promo',
                target: 'Visualization3d',
            })
            setTimeout(
                () =>
                    window!
                        .document!.getElementById('visualization3d')!
                        .scrollIntoView({
                            block: 'start',
                            behavior: 'auto',
                        }),
                400
            )
        } else if (
            advantagesServicesIsVisible &&
            visualizationAdvantagesIsVisible &&
            !advantages3DIsVisible
        ) {
            setAdvantages3DIsVisible(true)
            sendEvent('Click', {
                eventCategory: 'MoreOffer3',
                placement: 'Promo',
                target: 'Project3d',
            })
            setTimeout(
                () =>
                    window!
                        .document!.getElementById('Project3D')!
                        .scrollIntoView({
                            block: 'start',
                            behavior: 'auto',
                        }),
                400
            )
        } else return
    }

    return (
        <Wrapper>
            {!advantages3DIsVisible && (
                <ContainerStyled>
                    <ButtonDesktop onClick={showMoreHandle} id="showMorePromo">
                        <span>{t('showPromoMobile')}</span> <b>{t('CL')}</b>
                    </ButtonDesktop>
                    <ButtonMobile
                        onClick={showMoreHandle}
                        id="showMorePromoMobile"
                    >
                        {t('showPromoMobile')} <b>{t('CL')}</b>
                    </ButtonMobile>
                </ContainerStyled>
            )}
        </Wrapper>
    )
}
